import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

//ReactDOM.render(
//  <React.StrictMode>
//    <App />
//  </React.StrictMode>,
//  document.getElementById('root')
//);

// from https://www.w3schools.com/react/showreact.asp?filename=demo2_react_router
document.title = "Gilmore Place FPC";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
