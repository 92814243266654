import React, { Suspense, lazy } from 'react';
//import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const Picture = lazy(() => import('./components/Picture'));
const Layout = lazy(() => import('./components/Layout'));
const About = lazy(() => import('./components/About'));
const AudioLinks = lazy(() => import('./components/AudioLinks'));
const CommunionLinks = lazy(() => import('./components/CommunionLinks'));
const SingingLinks = lazy(() => import('./components/SingingLinks'));

// https://wwiw.w3schools.com/react/react_router.asp
// then browserrouter replaced with hashrouter, then back again with lazy loading and error redirection to About
function App() {

    return (
        <div className='container mt-5'>
          <Picture />
          <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
              <Layout />
              <Routes>
                <Route exact path="/" element={<AudioLinks />} />
                <Route path="about" element={<About />} />
                <Route path="audio" element={<AudioLinks />} />
                <Route path="communions" element={<CommunionLinks />} />
                <Route path="singing" element={<SingingLinks />} />
                <Route path="*" element={<AudioLinks />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </div>
    );
}

export default App;

//// from https://www.w3schools.com/react/showreact.asp?filename=demo2_react_router
//const root = ReactDOM.createRoot(document.getElementById('root'));
//root.render(<App />);
//document.title = "Gilmore Place FPC";
